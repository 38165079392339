//------------------------------------------------------------------------
// Dynamically load YouTube player using API
// https://developers.google.com/youtube/iframe_api_reference
//
// See markup example in /app/views/shared/shortcodes/_youtube.html.erb
//------------------------------------------------------------------------
/*global YT*/
"use strict";
import loadJS from "fg-loadjs";

// FIXME: Move to ENV var
const publicKey = "AIzaSyBcOo_NCFjRj1c3ERthXqDA1towkaM7W-I"; // window.APP && window.APP.YOUTUBE_PUBLIC_KEY;

class YoutubePlayer {
  constructor(el) {
    if (!publicKey) {
      console.warn("No YouTube API public key provided, can’t get video title");
    }

    this.el = el;

    // Get YouTube video ID
    this.videoId = this.el.getAttribute("data-youtube");

    if (!this.videoId) {
      console.warn("Missing YouTube video ID");
      return false;
    }

    // Get ID of element where video should be injected
    this.playerId = this.el.getAttribute("data-youtube-player");

    if (!this.playerId) {
      console.warn("Missing ID of YouTube video wrapper");
      return false;
    }

    // Find elements that need to be updated with video data
    this.titleEl = this.el.querySelectorAll('[data-youtube-title]');
    this.timeEl = this.el.querySelectorAll('[data-youtube-time]');
    this.imageEl = this.el.querySelectorAll('[data-youtube-image]');

    // Get playerVars from optional data attributes if present
    this.collectVars();

    // Get video info (e.g. title, time)
    this.lookupVideoData();

    // Create CustomEvent to trigger video to pause when another playes
    // Note: Requires CustomEvent polyfill for IE 11- and aOS 4.3- (in polyfills.js)
    this.playEvent = new CustomEvent('youtube-play', {
      detail: {
        id: this.playerId
      }
    });

    // Listen for video play event
    window.addEventListener('youtube-play', evt => {
      // Pause if the video that triggered the event is different from this instance
      if (evt.detail.id !== this.playerId && this.player) {
        this.player.pauseVideo();
      }
    });

    // Find link overlay
    this.linkEl = this.el.querySelector("[data-youtube-overlay]");

    // Check if video should load immediately
    this.shouldAutoload = this.el.hasAttribute('data-youtube-autoload') || !this.linkEl;

    if (this.shouldAutoload) {
        if (!this.linkEl) {
            console.warn('Missing YouTube player link overlay, loading video immediately', this.videoId);
        }
        // Disable autoplay
        this.playerVars.autoplay = 0;
        this.buildPlayer();
        return false;
    }

    // Load video player on click
    this.linkEl.addEventListener("click", evt => {
      evt.preventDefault();

      // Create the video player if not already present
      if (!this.player) {
        this.buildPlayer();
      }
    });
  }

  // Collect player vars from data attributes (if present)
  collectVars() {
    this.playerVars = {};
    this.playerVars.autohide =
      this.el.getAttribute("data-youtube-autohide") || 1;
    this.playerVars.autoplay =
      this.el.getAttribute("data-youtube-autoplay") || 1;
    // controls=2 does not work when enablejsapi=1
    this.playerVars.controls =
      this.el.getAttribute("data-youtube-controls") || 1;
    this.playerVars.iv_load_policy =
      this.el.getAttribute("data-youtube-iv_load_policy") || 3;
    this.playerVars.showinfo =
      this.el.getAttribute("data-youtube-showinfo") || 0;
    this.playerVars.rel = this.el.getAttribute("data-youtube-rel") || 0;
    this.playerVars.wmode = "opaque";
    // console.log('this.playerVars', this.playerVars);
  }

  // Plain JS AJAX request
  // https://plainjs.com/javascript/ajax/making-cors-ajax-get-requests-54/
  getCORS(url, success, error) {
    var xhr = new XMLHttpRequest();
    if (!("withCredentials" in xhr)) xhr = new XDomainRequest(); // fix IE8/9
    xhr.open("GET", url);
    xhr.onload = success;
    xhr.onerror = error;
    xhr.send();
    return xhr;
  }

  // Get video metadata using YouTube gdata API
  // https://developers.google.com/youtube/v3/getting-started
  getVideoData(callback) {
    let url = `https://www.googleapis.com/youtube/v3/videos?id=${
      this.videoId
    }&key=${publicKey}&part=snippet,contentDetails`;

    this.getCORS(
      url,
      request => {
        let response =
          request.currentTarget.response || request.target.responseText;
        callback(JSON.parse(response));
      },
      function() {
        this.videoTitle = "Title not found";
      }
    );
  }

  lookupVideoData() {
    this.videoTitle = this.el.getAttribute("data-youtube-title") || "";

    this.getVideoData(data => {
      if ("items" in data && data.items.length) {
        let video = data.items[0];

        // Save video title so we can add it to the iframe
        this.title = video.snippet.title;

        this.updateDOM({
          duration: video.contentDetails.duration,
          title: video.snippet.title,
          thumbnails: video.snippet.thumbnails
        });
      } else {
        console.warn("Can’t get YouTube data for video ID " + this.videoId);
      }
    });
  }

  // Convert duration string to something human readable (e.g. PT2H34M25S => 2:34:25)
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
  // Regex from https://gist.github.com/Fauntleroy/5167736#gistcomment-2094514
  updateDuration(data) {
    let duration = data.duration;
    let pattern = /([0-9]*H)?([0-9]*M)?([0-9]*S)?$/;
    let extracted = pattern.exec(duration);

    let hours = parseInt(extracted[1], 10) || 0;
    let a11yText = hours > 0 ? hours + " hours " : "";
    hours = hours > 0 ? hours + ":" : "";

    let minutes = parseInt(extracted[2], 10) || 0;
    a11yText += minutes > 0 ? minutes + " minutes " : "";

    let seconds = parseInt(extracted[3], 10) || 0;
    a11yText += seconds > 0 ? seconds + " seconds" : "";
    // Pad seconds with leading zero
    seconds = ("0" + seconds).slice(-2);

    this.timeEl.forEach(el => {
      el.setAttribute("datetime", duration);
      el.setAttribute("aria-label", a11yText);
      el.setAttribute("aria-hidden", "false");
      el.textContent = hours + minutes + ":" + seconds;
    });
  }

  // To improve performance, we’re hard-coding the poster images in the markup
  // use “srcset” so the browser can preload beofre JS runs. However, not every
  // video will have high-res images so we need to remove the missing ones.
  removeBrokenImages(data) {
    // Save available YouTube images to an array
    let youtubeImageUrls = [];

    for (const key in data.thumbnails) {
      youtubeImageUrls.push(data.thumbnails[key].url);
    }

    if (!youtubeImageUrls.length) {
      console.warn("Could not find YouTube poster images");
      return false;
    }

    // Cross-reference with the sources in the “srcset” attribute
    this.imageEl.forEach(image => {
      // Convert “srcset” values to an array
      let sources = image.getAttribute("srcset").split(",");
      sources = sources.map(d => d.trim());
      // Create new placeholder array to avoid altering the original during the loop below
      let finalSources = [];

      // Check each “srcset” URL against YouTube array
      for (var i = 0, len = sources.length; i < len; i++) {
        let imgUrl = sources[i].split(" ")[0];

        // Only keep the image sources YouTube has
        if (youtubeImageUrls.indexOf(imgUrl) > -1) {
          finalSources.push(sources[i]);
        }
      }

      // Update “srcset” attribute
      image.setAttribute("srcset", finalSources.join(","));
    });
  }

  updateDOM(data) {
    // Update title text
    if (this.titleEl.length) {
      this.titleEl.forEach(el => {
        el.textContent = data.title;
      });
    }

    // Update duration
    if (this.timeEl.length) {
      this.updateDuration(data);
    }

    // Remove any missing poster image sizes
    if (this.imageEl.length) {
      this.removeBrokenImages(data);
    }
  }

  buildPlayer() {
    var self = this;

    // Replace the placeholder element with the iframe
    this.player = new YT.Player(this.playerId, {
      videoId: this.videoId,
      playerVars: this.playerVars,
      events: {
        onReady: () => {
          let playerIframe = document.getElementById(this.playerId);

          // Update iframe title attribute for a11y
          playerIframe.title = this.title;

          // Focus the iframe since the link is now hidden
          playerIframe.focus();
        },
        onStateChange: evt => {
          // evt.data returns the player’s status
          // https://developers.google.com/youtube/iframe_api_reference#Events
          if (evt.data === YT.PlayerState.PLAYING) {
            window.dispatchEvent(self.playEvent);
          }
        }
      }
    });
  }
}

// Init video players
const buildPlayers = els => {
  els.forEach(el => {
    new YoutubePlayer(el);
  });
};

const init = () => {
  // Find video links
  let videos = document.querySelectorAll("[data-youtube]");

  // Load JS API and create video players if present
  if (publicKey && videos.length) {
    // Check if YouTube JS API has already been loaded
    // https://developers.google.com/youtube/iframe_api_reference
    if (typeof window.onYouTubeIframeAPIReady !== "undefined") {
      buildPlayers(videos);
    } else {
      // Set custom “onYouTubeIframeAPIReady” callback that will fire once the script has downloaded
      window.onYouTubeIframeAPIReady = function() {
        buildPlayers(videos);
      };

      // Get YouTube JS API script
      loadJS("https://www.youtube.com/iframe_api", function() {
        // YouTube script automatically calls onYouTubeIframeAPIReady()
      });
    }
  }
};

// Init on initial page load
init();
